<template>
  <div class="service-form-part">
    <ServiceTypeField
      :choices="serviceTypes"
      @result="setServiceForType($event.value[0])"
      v-on="$listeners"
    />

    <h2 class="form-modal__part-title">
      {{ serviceSelectionTitle || $t('serviceSelectionTitle') }}
    </h2>

    <CustomDimensionSelectableField
      :choices="serviceSelections"
      :is-optional="true"
      :multiple="true"
      @result="serviceSelection = $event.value[0]"
      v-on="$listeners"
    />

    <ServiceField ref="service" hidden v-on="$listeners" />
  </div>
</template>

<script>
import CustomDimensionSelectableField from 'chimera/all/components/form/fields/customDimension/CustomDimensionSelectableField.vue'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import ServiceTypeField from 'chimera/all/components/form/fields/serviceType/ServiceTypeField'
import { Selectable } from 'chimera/all/components/models/Selectable'
import {
  solarPanelsPurchaseConsumerBE,
  solarPanelsPurchaseConsumerNL,
  solarPanelsPurchaseCorporateBE,
} from 'chimera/solarPanels/service'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    CustomDimensionSelectableField,
    ServiceField,
    ServiceTypeField,
  },

  props: {
    serviceSelectionTitle: {
      type: String,
      default: '',
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceTypes: [
        new Selectable(
          'Particulier',
          'Particulier',
          solarPanelsPurchaseConsumerBE.id,
        ),
        new Selectable(
          'Zakelijk',
          'Zakelijk',
          solarPanelsPurchaseCorporateBE.id,
        ),
      ],

      serviceSelections: [
        new Selectable(
          'Thuisbatterijen',
          'Thuisbatterijen',
          'Thuisbatterijen',
          {
            consumer: solarPanelsPurchaseConsumerNL,
            corporate: solarPanelsPurchaseConsumerNL,
          },
        ),
        new Selectable('Airco', 'Airco', 'Airco', {
          consumer: solarPanelsPurchaseConsumerNL,
          corporate: solarPanelsPurchaseConsumerNL,
        }),
        new Selectable('Warmtepompen', 'Warmtepompen', 'Warmtepompen', {
          consumer: solarPanelsPurchaseConsumerNL,
          corporate: solarPanelsPurchaseConsumerNL,
        }),
      ],

      serviceSelection: undefined,
    }
  },

  methods: {
    /**
     * @param {object}  type
     */
    setServiceForType(type) {
      if (!type) {
        return
      }

      const serviceType = this.serviceTypes.find(
        (serviceType) => serviceType.id === type.id,
      )
      this.$refs.service.setValue(serviceType.value)
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "serviceSelectionTitle": "Type opdracht",
    "consumerService": "Particulier",
    "corporateService": "Zakelijk"
  },
  "nl-BE": {
    "serviceSelectionTitle": "Type opdracht",
    "consumerService": "Particulier",
    "corporateService": "Zakelijk"
  },
  "es-ES": {
    "serviceSelectionTitle": "Tipo de solicitud",
    "consumerService": "Particular",
    "corporateService": "Empresas"
  },
  "de-DE": {
    "serviceSelectionTitle": "Auftragstyp",
    "consumerService": "Privatkunde",
    "corporateService": "Geschäftskunde"
  },
  "fr-BE": {
    "serviceSelectionTitle": "Type de projet",
    "consumerService": "Particulier",
    "corporateService": "Professionnels"
  },
  "fr-FR": {
    "serviceSelectionTitle": "Type de projet",
    "consumerService": "Particulier",
    "corporateService": "Professionnels"
  },
  "it-IT": {
    "serviceSelectionTitle": "Tipo di progetto",
    "consumerService": "Privato",
    "corporateService": "Aziendale"
  }
}
</i18n>
